import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { actions, RootState, ThunkDispatchType, AirlineNote, Airline, AnyNote } from '../store';
import { InfoCardFields } from '../utils/types';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import AirlinePicker from '../components/InfoCard/AirlineCard/AirlinePicker';
import NoteOnboarding from '../components/NoteOnboarding';
import { NoteType, PAGE_URL, EVENT_TYPE, EXEC_CARD_TYPES } from '../constants';
import CardsPageHeader from '../components/Common/CardsPageHeader';
import { trackEventWithExtra } from '../utils/appAnalyticsUtils';
import { getAirlineNoteTemplate } from '../store/templates';
import InfoCardContainer from '../components/InfoCard/InfoCardContainer';
import { CABINET_ICONS, EMOJIS } from '../icons';
import { NO_NOTES_GRAPHIC_SRC } from '../resourceUrls';
import CabinetPage from '../components/Common/CabinetPage';
import { CabButton, CabIcon } from '@CabComponents';
import { CabModal } from '@CabComponents/CabModal';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CabSpinner from '@CabComponents/CabSpinner';
import { IoArrowBack } from 'react-icons/io5';


const airlineCopy = {
  title: "Let's get started with airline information",
  icon: EMOJIS.AIRLINES,
  icon_url: CABINET_ICONS.PLANE.icon_url,
  listCaption: "Here's what we'll help you access on the fly:",
  info: [ 
    'Airline loyalty numbers',
    'Preferences like seats and meals', 
    'Unused cancelled tickets'
  ]
};


type RouteParams = { leaderId: string; };


export const Airlines = (): ReactElement => {

  const airlines = useSelector((state: RootState) => state.airlines);
  const leaders = useSelector((state: RootState) => state.leaders);

  const dispatch = useDispatch<ThunkDispatchType>();

  const fetchAirlineNotes = useCallback((leaderId: number) => 
    dispatch(actions.airlines.fetchAirlineNotes(leaderId)), [dispatch]);
  const createAirlineNote = (note: AirlineNote) => dispatch(actions.airlines.createAirlineNote(note));
  const updateAirlineNote = (note: AirlineNote) => dispatch(actions.airlines.updateAirlineNote(note));
  const deleteAirlineNote = (noteId: number) => dispatch(actions.airlines.deleteAirlineNote(noteId));

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<RouteParams>();
  const currentLeader = useMemo(() => {
    const newCurrentLeader = leaders.leaders.find(leader => leader.id.toString() === params.leaderId);
    if (leaders.loaded && !newCurrentLeader) {
      navigate(PAGE_URL.DASHBOARD);
    }
    return newCurrentLeader;
  }, [leaders.leaders, leaders.loaded, navigate, params.leaderId]);

  useEffect((): void => {
    if (currentLeader && currentLeader.id > 0) {
      fetchAirlineNotes(currentLeader.id);
    }
  }, [fetchAirlineNotes, currentLeader]);

  const [newAirlineNote, setNewAirlineNote] = useState<AirlineNote>(getAirlineNoteTemplate());
  const [idToDelete, setIdToDelete] = useState(-1);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [addAirlineMode, setAddAirlineMode] = useState(false);
  const [addAirlineModalOpen, setAddAirlineModalOpen] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect((): void => {
    const matchUrl = matchPath(location.pathname, "/executive/:id/airlines");
    // checking URL to hotfix modal opening when it shouldn't
    if (airlines.loaded && airlines.notes.length === 0 && matchUrl) {
      setShowOnboarding(true);
    }
  }, [airlines.loaded, airlines.notes.length, location.pathname]);
  
  const handleOpenDeleteAlert = (airlineNoteId: number): (() => void) => 
    (): void => {
      setIdToDelete(airlineNoteId);
      setDeleteAlertOpen(true);
    };

  const handleConfirmDelete = (): void => {
    setDeleteAlertOpen(false);
    deleteAirlineNote(idToDelete);
    trackEventWithExtra({ 
      eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD, 
      extra: {category: EXEC_CARD_TYPES.AIRLINES} 
    });
  };

  const handleOpenAddModal = (): void => {
    setAddAirlineModalOpen(true);
  };

  const handleCloseAddModal = (): void => {
    setAddAirlineModalOpen(false);
  };

  const handleSelectNewAirline = (airline: Airline): void => {
    setNewAirlineNote({...newAirlineNote, airline_data: airline});
    setAddAirlineModalOpen(false);
    setAddAirlineMode(true);
  };

  const handleCancelAddAirline = (): void => {
    setAddAirlineMode(false);
  };

  const handleSaveAirlineNote = (airlineNote: AnyNote): Promise<void> => { 
    if (addAirlineMode && currentLeader) {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.CREATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.AIRLINES} 
      });
      return createAirlineNote({...airlineNote as AirlineNote, leader: currentLeader.id})
        .then((): void => {
          setAddAirlineMode(false);
        });
    } else {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.UPDATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.AIRLINES} 
      });
      return updateAirlineNote(airlineNote as AirlineNote);
    }
  };

  const handleOnboardingClose = (): void => {
    setShowOnboarding(false);
  };

  const loaded = airlines.loaded;
  const showNotes = currentLeader && loaded && airlines.notes.length > 0 
  && airlines.notes[0].leader === currentLeader.id;
  
  const infoFields: InfoCardFields<AirlineNote>[] = [
    {label: 'Loyalty #', key: 'loyalty_number'},
    {label: 'Username', key: 'username'}, 
    {label: 'General Notes', key: 'notes'}
  ];

  const hasEditPermission = currentLeader?.permissions.standard.airlines.edit;
  
  const renderAirlineCards = (): ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item hidden={true}>
          <InfoCardContainer<AirlineNote> note={newAirlineNote} saveNote={handleSaveAirlineNote} 
            addMode={addAirlineMode} title="Airline" openDeleteAlert={handleOpenDeleteAlert(newAirlineNote.id)} 
            noteType={NoteType.AIRLINE}
            infoFields={infoFields} cancelAdd={handleCancelAddAirline} currentLeader={currentLeader}/>
        </Grid>
        {showNotes && airlines.notes.map((airlineNote: AirlineNote): ReactElement =>
          <Grid item xs={12} md={6} key={airlineNote.id}>
            <Box height='100%'>
              <InfoCardContainer<AirlineNote> note={airlineNote} saveNote={handleSaveAirlineNote} addMode={false} 
                title="Airline" openDeleteAlert={handleOpenDeleteAlert(airlineNote.id)} noteType={NoteType.AIRLINE} 
                infoFields={infoFields} currentLeader={currentLeader}/>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPlaceholder = (): ReactElement | null => {
    if (loaded && airlines.notes.length === 0) {
      return (
        <Box display='flex' width='100%' flexDirection='column' height="100%" alignItems="center" 
          justifyContent="center" gap={1}>
          <Box component={'img'} src={NO_NOTES_GRAPHIC_SRC} alt="No notes yet" />
          <Typography variant='h5'>No Airlines have been added yet</Typography>
          {hasEditPermission &&
             <CabButton onClick={handleOpenAddModal}>
               Add your first Airline
             </CabButton>
          }
        </Box>
      );
    } else {
      return null;
    }
  };

  const onBack = (): void => navigate(`${PAGE_URL.EXECUTIVE}/${currentLeader?.id}`);

  const renderSpinnerOrContent = (): ReactElement => {
    if (!loaded) {
      return <Box display='flex' height="100%" alignItems="center" justifyContent="center">
        <CabSpinner scale={4}/>
      </Box>;
    } else {
      return (
        <>
          <Box width='100%' display='flex' flexDirection='column' padding={2} gap={2}>
            {currentLeader && (
              <Box width='100%' display='flex'>
                <CabButton
                  onClick={onBack}
                  icon={<CabIcon Icon={IoArrowBack} />}
                  buttonType="tertiary"
                  color="accent"
                  size="small"
                >
                  Back
                </CabButton>
              </Box>
            )}
            <Box>
              {renderPlaceholder()}
              {renderAirlineCards()}
            </Box>
          </Box>
          <CabModal
            open={deleteAlertOpen}
            onClose={() => setDeleteAlertOpen(false)}
            isAlert
            closeIcon
            title='Warning'
            text='Are you sure you want to delete this airline?'
            noFullScreen
            actionButtons={
              <>
                <CabButton buttonType='tertiary' color='primary' onClick={() => setDeleteAlertOpen(false)}>
                  Cancel
                </CabButton>
                <CabButton onClick={handleConfirmDelete}>
                  Yes, delete it
                </CabButton>
              </>
            }
          />
          <AirlinePicker showModal={addAirlineModalOpen} selectAirline={handleSelectNewAirline} 
            cancelAddAirline={handleCloseAddModal} />
        </>
      );
    }
  };

  return (
    <CabinetPage
      pageName={'ExecAirlines'}
      headerContent={<>
        {currentLeader && 
          <CardsPageHeader 
            title="Airlines" 
            addCard={hasEditPermission ? handleOpenAddModal : undefined} />
        }
      </>
      }
    >
      {renderSpinnerOrContent()}
      <CabModal
        closeOnBackdropClick
        open={showOnboarding}
        onClose={handleOnboardingClose}
        title={(
          <Box display="flex" alignItems="center" gap={1} paddingTop={2}>
            <Box component={'img'} src={airlineCopy.icon_url} alt={'airplane'} height={40} />
            {airlineCopy.title}
          </Box>
        )}
        actionButtons={
          <CabButton size="large" buttonType="primary" onClick={handleOnboardingClose}>
            Got it!
          </CabButton>
        }
        sx={{ ".MuiPaper-root": { minWidth: '500px' } }}
      >
        <NoteOnboarding copy={airlineCopy} />
      </CabModal>
    </CabinetPage>
  );
};

export default Airlines;